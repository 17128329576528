'use strict';

/**
 * Funtion to call the ESW URL onclick of LL Checkout Button
 */
function updateCountryList() {
    $(document).on('click', '.btnCheckoutESW', function (e) {
        e.preventDefault();
        $('.eshopworld-loader').removeClass('d-none');
        var urlParams = {
            methodID: $('.shippingMethods').find(':selected').attr('data-shipping-id')
        };
        $(this).addClass('disabled');
        $.ajax({
            type: 'get',
            url: $(this).attr('data-url'),
            data: urlParams,
            success: function (response) {
                window.open(response.redirectURL, '_self');
            }
        });
    });
}

$(document).ready(function () {
    updateCountryList();
});
